import styled from 'styled-components';
import { hexToRGBA } from 'utils/color';

export const Style = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100vw;
  box-sizing: border-box;
  padding: 0 20vw;
  justify-content: ${(props) => props.fullSelectAlign};
  height: 100vh;
  background: url(${(props) => props.fullScreenSiteBgImage});
  background-size: cover;
  background-position: center center;
  .mask-bg {
    background: ${(props) => props.colorFullScreenMask};
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  .shop-select-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    .zent-select {
      width: 320px !important;
    }
    .enter-btn {
      height: 34px !important;
      line-height: 34px !important;
      margin-left: 8px;
    }
    .zent-btn.zent-btn-primary {
      color: ${(props) => props.colorFullScreenButton} !important;
      background: ${(props) => props.colorFullScreenButtonBg} !important;
      position: relative;
      span {
        position: relative;
        z-index: 10;
      }
    }
    .zent-btn.zent-btn-disabled {
      opacity: 1 !important;
      background: white !important;
      & ::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: ${(props) => hexToRGBA(props.colorFullScreenButtonBg, 0.3)} !important;
      }
    }
  }
  @media screen and (max-width: 750px) {
    padding: 0 24px;
    justify-content: center;
    .shop-select-wrapper {
      align-items: center;
      flex-direction: column;
      width: 100%;
      .zent-select {
        width: 100%;
        max-width: 327px;
      }
      .enter-btn {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  }
`;
