import React, { useEffect, useState, useContext, useRef } from 'react';
import { Button } from 'zent';
import { MultiSelect } from '../../design/components/multi-select';
import { ContextManager } from '../../context/context-manager';
import siteApi from 'api/site';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { Style } from './style';

const DEFAULT_BG_IMAGE = 'https://img01.yzcdn.cn/upload_files/2021/11/03/Fu3iKgw2Hn5kF1TGhfB8AUGg9Fyh.jpeg';

const StoreSelect = (props) => {
  const intl = useIntl();
  const context = useContext(ContextManager) || {};
  const { theme: themeData, kdtId } = context;
  const sectionThemeDataOrigin = themeData?.current?.sections?.['site-select'] || {
    block_order: [],
    blocks: {},
    settings: {},
  };

  const [sectionThemeData, setSectionThemeData] = useState(sectionThemeDataOrigin);

  const [currentSiteDomain, setCurrentSiteDomain] = useState('');

  const selectChange = (domain) => {
    setCurrentSiteDomain(domain);
  };

  const onEnterSite = () => {
    if (window?.isEdit) {
      return;
    }
    if (currentSiteDomain) {
      window.location.href = currentSiteDomain;
    }
  };

  useEffect(() => {
    //不是装修模式，并且列表项为空的时候，初始化一下
    if (!window?.isEdit && isEmpty(sectionThemeData?.block_order)) {
      const type = 'icondianputouxiang';
      siteApi.getHqChainSites({ rootKdtId: kdtId }).then((res) => {
        res.map((item, index) => {
          const { kdtId, logo, name, mainHost } = item;
          const key = type + index;
          sectionThemeData.block_order.push(key);
          sectionThemeData.blocks[key] = {
            type,
            settings: {
              image: item.logo,
              kdtId,
              mainHost,
              site_name: name,
            },
          };
        });
        setSectionThemeData({ ...sectionThemeData });
      });
    } else {
      setSectionThemeData(sectionThemeDataOrigin);
    }
  }, [themeData]);

  let {
    full_select_align: fullSelectAlign = 'center',
    full_screen_site_bg_image: fullScreenSiteBgImage,
    color_full_screen_mask: colorFullScreenMask = 'rgba(0,0,0,0.3)',
    color_full_screen_button: colorFullScreenButton = '#ffffff',
    color_full_screen_button_bg: colorFullScreenButtonBg = '#000000',
  } = sectionThemeData?.settings || {};

  if (!fullScreenSiteBgImage) {
    fullScreenSiteBgImage = DEFAULT_BG_IMAGE;
  }

  return (
    <Style
      fullSelectAlign={fullSelectAlign}
      fullScreenSiteBgImage={fullScreenSiteBgImage}
      colorFullScreenMask={colorFullScreenMask}
      colorFullScreenButton={colorFullScreenButton}
      colorFullScreenButtonBg={colorFullScreenButtonBg}
    >
      <div className='mask-bg'></div>
      <div className='shop-select-wrapper'>
        <MultiSelect themeData={themeData} sectionThemeData={sectionThemeData} callback={selectChange} />
        <Button type='primary' className='enter-btn' onClick={onEnterSite} disabled={!currentSiteDomain}>
          {intl.formatMessage({
            id: 'ef96869f3fc14c6f872214bc060831d1',
            defaultMessage: '进入',
          })}
        </Button>
      </div>
    </Style>
  );
};

export default StoreSelect;
